import { render, staticRenderFns } from "./Intro.vue?vue&type=template&id=67923976&scoped=true"
import script from "./Intro.vue?vue&type=script&lang=js"
export * from "./Intro.vue?vue&type=script&lang=js"
import style0 from "./Intro.vue?vue&type=style&index=0&id=67923976&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67923976",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VButton: require('/home/runner/work/tomasu-website/tomasu-website/app/components/buttons/VButton.vue').default,BaseVisual: require('/home/runner/work/tomasu-website/tomasu-website/app/components/media/BaseVisual.vue').default})
