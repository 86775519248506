import { arrayOf, oneOf, oneOfType, shape, string, bool } from 'vue-types';
import { images } from '@/types/image';
import { visualList } from '@/types/visual';
import { isNullableString } from '@/types/isNullable';
import { customLinkArray } from '@/types/customLink';

/**
 * Base module shape.
 */
export const moduleShape = {
  __typename: string(),
  id: string().isRequired,
  siteId: oneOfType([String, Number]).isRequired,
  type: string().isRequired,
};

export const introShape = {
  ...moduleShape,
  body: string(),
  link: customLinkArray,
  visual: visualList,
  ltr: bool(),
};

export const richTextShape = {
  ...moduleShape,
  body: string(),
  link: customLinkArray,
};

export const inlineImagesShape = {
  ...moduleShape,
  images,
};

export const quoteShape = {
  ...moduleShape,
  quote: string(),
  quotee: string(),
  subtitle: string(),
  profilePicture: images,
  visual: visualList,
  textAlignment: oneOf(['left', 'center', 'right']).def('left'),
};

export const visualShape = {
  ...moduleShape,
  visual: visualList,
  textInset: arrayOf(
    shape({
      heading: string(),
      body: string(),
    }).loose,
  ),
  buttonLink: arrayOf(
    shape({
      label: string(),
      externalUrl: string(),
    }).loose,
  ),
  alignment: oneOf(['left', 'center', 'right']).def('left'),
};

export const testimonialShape = {
  ...moduleShape,
  heading: string(),
  quote: string(),
  logo: images,
  decorativeImage: images,
};

export const accordionShape = {
  ...moduleShape,
  heading: string(),
  firstItemExpanded: bool(),
  items: arrayOf(
    shape({
      heading: isNullableString(),
      body: string(),
    }).loose,
  ),
};

const supportedModules = [introShape, richTextShape, quoteShape, visualShape, testimonialShape, accordionShape].map(
  (shapeObject) => shape(shapeObject).loose,
);

export const moduleType = oneOfType(supportedModules);
export const modules = arrayOf(moduleType);
