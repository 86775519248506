import { bool, oneOf } from 'vue-types';

export const CONTEXT_DEFAULT = 'default';
export const CONTEXT_SIDEBAR = 'sidebar';

export default {
  props: {
    context: oneOf([CONTEXT_DEFAULT, CONTEXT_SIDEBAR]).def(CONTEXT_DEFAULT),
    gap: bool().def(true),
  },
  computed: {
    /**
     * On the project detail page, modules are shown in sidebar.
     */
    compact() {
      return this.context === CONTEXT_SIDEBAR;
    },
  },
};
