
import { introShape } from '@/types/modules';
import module from '~/mixins/module';
import customLink from '~/mixins/customLink';

export default {
  mixins: [module, customLink],
  inheritAttrs: false,
  props: {
    ...introShape,
  },
  computed: {
    visualProps() {
      return this.$first(this.visual);
    },
  },
};
